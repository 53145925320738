import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
const moment = require('moment')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      fields: [
        { 
          key: 'code', 
          label: 'รหัสลูกหนี้', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'debtor', 
          label: 'ลูกหนี้', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
         { 
          key: 'debt', 
          label: 'ยอดค้างชำระ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'duedate', 
          label: 'วันที่ครบกำหนด', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'over', 
          label: 'เกินกำหนด (วัน)',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        }
      ],
      items: [
        {
          _id: '123456',
          duedate: new Date(),
          code: 'CT-001',
          debtor: 'ไกรวิชญ์',
          debt: 2000,
          over: 0
        },
        {
          _id: '123455',
          duedate: new Date(),
          code: 'CT-002',
          debtor: 'เกริกวิทย์',
          debt: 1000,
          over: 0
        },
        {
          _id: '123454',
          duedate: moment().subtract(2, "days"),
          code: 'CT-003',
          debtor: 'กชมน',
          debt: 1000,
          over: 2
        }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          this.items = item
        }, 1000)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}